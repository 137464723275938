import React, {forwardRef, useState, useImperativeHandle} from "react"

const VideoModal = forwardRef((props, ref) => {
  const [showModal, setShowModal] = React.useState(false);

  useImperativeHandle(ref, () => ({
    hide() {
      setShowModal(false);
    },
    show() {
      setShowModal(true);
    },
  }));

  if (showModal) {
    return (
      <div className="z-50 fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity" onClick={ () => setShowModal(false) }>
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0 h-full w-full">
            <div className="relative" style={{"padding-bottom":"40%", "width": "65%"}}>
              <iframe className="h-full w-full" src={props.videoSrc} frameBorder="0"
                      webkitallowfullscreen={true} mozallowfullscreen={true} allowFullScreen={true}
                      style={{width: "100%", height: "100%", position: "absolute", "top": "0", "left": "0"}}>
              </iframe>
            </div>
          </div>
        </div>
      </div>)
  }
  return null;
});

export default VideoModal;