import React, {useRef} from "react"
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import { Button, Link, SignupButton, Row, Typography,  Container, Section, SectionTitle, Faqs, FeatureRows, Article, FeatureGrid } from "components/ui";
import Image from "components/image"
import {AnchorLink} from "gatsby-plugin-anchor-links"
import VideoModal from '../landings/_videoModal';

const SecurityPage = () => {
  const videoRef = useRef(null);
//<Button className="mt-6" color="white" size="large">Our Security Doc</Button>
  return (
    <Layout.Layout>
      <Layout.SEO refKey="security" />
      <Layout.HeroEmpty></Layout.HeroEmpty>
      <Ui.Section color="primary">
        <Ui.Container>
          <Ui.Row>
            <div className="col-md-12 text-center">
              <h1 className="font-heading text-4xl md:text-5xl leading-tight font-black text-dark">
                Security and privacy are at the core of what we do!
              </h1>
              <p>We build features and architecture our systems in a security-first manner.</p>
            </div>

            <div className="grid md:grid-cols-4 gap-16 text-left mt-24">
              <div>
                <i className="fad fa-circle-1 text-primary text-6xl" />
                <h3 className="text-xl font-bold mt-4">Top-notch datacenters</h3>
                <p className="text-base">Our applications are running on secure infrastructure operated by AWS and located in Europe and USA.</p>
              </div>
              <div>
                <i className="fad fa-circle-2 text-primary text-6xl" />
                <h3 className="text-xl font-bold mt-4">Secure by design</h3>
                <p className="text-base">SimpleBackups is built in a way that no backup data is ever stored or passed through our servers, which drastically reduces the risk of data breach.</p>
              </div>
              <div>
                <i className="fad fa-circle-3 text-primary text-6xl" />
                <h3 className="text-xl font-bold mt-4">User data restrictions</h3>
                <p className="text-base">No database access is given to any employees or third-parties.</p>
              </div>
              <div>
                <i className="fad fa-circle-4 text-primary text-6xl" />
                <h3 className="text-xl font-bold mt-4">Backup encryption for all</h3>
                <p className="text-base">All backups, on any plan can be fully encrypted using your own RSA keys, meaning you're the only one able to decrypt them.</p>
              </div>
            </div>
            <div className="flex items-center justify-around gap-8">
              <div className="flex mt-24">
                <div className=" w-40 ">
                  <Image filename="gdpr-compliant"></Image>
                </div>
                <div className="w-40 ">
                  <Image filename="iso27001-horizontal.png"></Image>
                </div>
              </div>
            </div>
          </Ui.Row>
        </Ui.Container>
      </Ui.Section>

      <Ui.Section color="alt">
        <Ui.Container>
          <Ui.Row>
            <div className="lg:grid lg:grid-cols-3 lg:gap-8">
              <div>
                <h2 className="text-3xl font-extrabold ">
                  Your account security
                </h2>
                <p className="mt-4 text-lg text-warm-gray-500"></p>
              </div>
              <div className="mt-12 lg:mt-0 lg:col-span-2">
                <dl className="space-y-12">
                  <div>
                    <dt className="text-lg font-bold">
                      Oauth 2 and 2-Factor Authentication
                    </dt>
                    <dd className="mt-2 text-base text-warm-gray-500">
                      You can use your Github or DigitalOcean OAuth 2 integration to log in to SimpleBackups and we also support 2FA.
                    </dd>
                  </div>

                  <div>
                    <dt className="text-lg font-bold text-warm-gray-900">
                      Manage teams and users permissions
                    </dt>
                    <dd className="mt-2 text-base text-warm-gray-500">
                      Manage who can access your resources, organise them by group and restrict level of access for each team member.
                    </dd>
                  </div>

                  <div>
                    <dt className="text-lg font-bold text-warm-gray-900">
                      What happens if my account on SimpleBackups is compromised?
                    </dt>
                    <dd className="mt-2 text-base text-warm-gray-500">
                      The beauty of SimpleBackups is that it allows us to answer all the following questions by a 'No'.<br/>
                      We tried other solutions in the past, and this was not the case, which is why we believe that our solution is the safest bet.
                      <ul className="list-disc pl-12 pt-4">
                        <li>Storage secret can be viewed from my account? <strong>No.</strong></li>
                        <li>Servers SSH keys could be retrieved from my account? <strong>No.</strong></li>
                        <li>Servers SSH passwords could be viewed from my account? <strong>No.</strong></li>
                        <li>Backups on my offsite storage could be deleted by any means? <strong>No.</strong></li>
                        <li>Encrypted backups could be decrypted by anyone other than the private key owner? <strong>No.</strong></li>
                        <li>If SimpleBackups disappears one day, or if my account is deleted, will I not be able to retrieve my backups? <strong>No.</strong></li>
                      </ul>
                    </dd>
                  </div>

                </dl>
              </div>
            </div>
          </Ui.Row>
        </Ui.Container>
      </Ui.Section>

      <Ui.Section color="">
        <Ui.Container>
          <Ui.Row>
            <div className="lg:grid lg:grid-cols-3 lg:gap-8">
              <div>
                <h2 className="text-3xl font-extrabold ">
                  Your backups security
                </h2>
                <p className="mt-4 text-lg text-warm-gray-500">Your account, team settings, ...</p>
              </div>
              <div className="mt-12 lg:mt-0 lg:col-span-2">
                <dl className="space-y-12">
                  <div>
                    <dt className="text-lg font-bold">
                      Encryption with your own RSA key
                    </dt>
                    <dd className="mt-2 text-base text-warm-gray-500">
                      Backup encryption is fully managed by your own RSA key meaning you're the only one able to read it. Not us. So don't lose your key!
                    </dd>
                  </div>

                  <div>
                    <dt className="text-lg font-bold text-warm-gray-900">
                      We log activity for all backup operations
                    </dt>
                    <dd className="mt-2 text-base text-warm-gray-500">
                      Any activity on your backup is logged and displayed on your backup view page.
                    </dd>
                  </div>

                  <div>
                    <dt className="text-lg font-bold text-warm-gray-900">
                      AES-256 encryption across the board
                    </dt>
                    <dd className="mt-2 text-base text-warm-gray-500">
                      All backup, server and storage related data is encrypted using AES-256. This means it's impossible to read the data without the encryption key in case of a data breach.

                      Additionally:
                      <ul className="list-disc pl-12 pt-4">
                        <li>All backup jobs that run do not work outside the backup directory you set or the /tmp directory</li>
                        <li>The scripts that run are thoroughly tested on our infrastructure continuously (we have automated test cases for all backup types, unit-tests, and minutely running backups for testing)</li>
                        <li>The backup scripts run for all users all the time, including us as well, since SimpleBackups uses itself to maintain its own backups</li>
                      </ul>
                    </dd>
                  </div>

                  <div>
                    <dt className="text-lg font-bold text-warm-gray-900">
                      Store your backups on your storage
                    </dt>
                    <dd className="mt-2 text-base text-warm-gray-500">
                      You can bring your own storage and connect it to SimpleBackups. In this case the data is sent from your server to your storage, not passing by our servers at anytime.
                    </dd>
                  </div>

                </dl>
              </div>
            </div>
          </Ui.Row>
        </Ui.Container>
      </Ui.Section>

      <Ui.Section color="alt">
        <Ui.Container>
          <Ui.Row>
            <div className="lg:grid lg:grid-cols-3 lg:gap-8">
              <div>
                <h2 className="text-3xl font-extrabold ">
                  Your storage security
                </h2>
                <p className="mt-4 text-lg text-warm-gray-500"></p>
              </div>
              <div className="mt-12 lg:mt-0 lg:col-span-2">
                <dl className="space-y-12">
                  <div>
                    <dt className="text-lg font-bold">
                      Leverage S3 permissions
                    </dt>
                    <dd className="mt-2 text-base text-warm-gray-500">
                      S3 permissions allows you to easily restrict access to only certain folders. We document this process for most providers and encourage you to use the least possible permissions.
                    </dd>
                  </div>

                  <div>
                    <dt className="text-lg font-bold text-warm-gray-900">
                      Dropbox & Google Drive Permissions
                    </dt>
                    <dd className="mt-2 text-base text-warm-gray-500">
                      When connecting to Dropbox or Google Drive, SimpleBackups access is restricted to its own folder only.
                    </dd>
                  </div>

                  <div>
                    <dt className="text-lg font-bold text-warm-gray-900">
                      Support for object-locked storage
                    </dt>
                    <dd className="mt-2 text-base text-warm-gray-500">
                      You have the option to connect an immutable storage with object-lock activated.
                    </dd>
                  </div>

                  <div>
                    <dt className="text-lg font-bold text-warm-gray-900">
                      Support for read-only storage
                    </dt>
                    <dd className="mt-2 text-base text-warm-gray-500">
                      You have the option to connect a read-only storage to ensure that data is never deleted by any means.
                    </dd>
                  </div>

                  <div>
                    <dt className="text-lg font-bold text-warm-gray-900">
                      SimpleStorage infrastructure & permissions
                    </dt>
                    <dd className="mt-2 text-base text-warm-gray-500">
                      SimpleStorage is hosted on AWS and is fully managed by us.
                      A unique IAM will be created for each user having their own access to their own bucket space. This guarantees a fully secured storage environment.
                    </dd>
                  </div>

                </dl>
              </div>
            </div>
          </Ui.Row>
        </Ui.Container>
      </Ui.Section>

      <Ui.Section>
        <Ui.Container>
          <Ui.Row>
            <div className="lg:grid lg:grid-cols-3 lg:gap-8">
              <div>
                <h2 className="text-3xl font-extrabold ">
                  Your servers security
                </h2>
                <p className="mt-4 text-lg text-warm-gray-500"></p>
              </div>
              <div className="mt-12 lg:mt-0 lg:col-span-2">
                <dl className="space-y-12">
                  <div>
                    <dt className="text-lg font-bold">
                      Data encryption
                    </dt>
                    <dd className="mt-2 text-base text-warm-gray-500">
                      All data you enter in SimpleBackups is encrypted using AES-256 encryption. This means it's impossible to read the data without the key in case of a data breach. We never store passwords/secrets in clear text.
                    </dd>
                  </div>

                  <div>
                    <dt className="text-lg font-bold text-warm-gray-900">
                      We don't need a root access!
                    </dt>
                    <dd className="mt-2 text-base text-warm-gray-500">
                      The SSH access provide only requires read access to the folders you need to back up, nothing more!
                    </dd>
                  </div>

                </dl>
              </div>
            </div>
          </Ui.Row>
        </Ui.Container>
      </Ui.Section>

      <Ui.Section color="alt">
        <Ui.Container>
          <Ui.Row>
            <Ui.Typography tag="h2" className="text-center">Compliance & Security Standards</Ui.Typography>
            <div className="md:flex gap-8 mt-16">

              <div className="md:flex flex md:flex-col gap-2 md:w-1/3">
                <div><Image filename="gdpr-compliant.png" alt="GDPR compliance" /></div>
                <div><Image filename="ssl-encryption.png" alt="SSL encryption" /></div>
                <div className="flex gap-4">
                  <Image filename="hosted-aws-badge.png" alt="Hosted on AWS" />
                  <Image filename="secured-cloudflare-badge.png" alt="Secured by CloudFlare" />
                </div>
                <div><Image filename="iso27001-horizontal.png" alt="IS27001" /></div>
              </div>

              <dl
                className="w-2/3 grid md:grid-cols-2 gap-6 h-auto">

                <div className="relative">
                  <dt>
                    <svg className="absolute h-6 w-6 text-green" x-description="Heroicon name: outline/check"
                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                         stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"></path>
                    </svg>
                    <p className="ml-9 text-lg leading-6 font-medium text-gray-900">European based company</p>
                  </dt>
                  <dd className="mt-2 ml-9 text-base text-gray-500">
                    We're based in Belgium and we're a European based company. We follow the European Data Protection Regulation (GDPR) to ensure the protection of your data no matter where you are in the world.
                  </dd>
                </div>

                <div className="relative">
                  <dt>
                    <svg className="absolute h-6 w-6 text-green" x-description="Heroicon name: outline/check"
                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                         stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"></path>
                    </svg>
                    <p className="ml-9 text-lg leading-6 font-medium text-gray-900">ISO 27001 Certified</p>
                  </dt>
                  <dd className="mt-2 ml-9 text-base text-gray-500">
                    SimpleBackups is ISO 27001 certified. This means that we have a framework of policies and procedures that includes all legal, physical and technical controls involved in our information risk management processes.
                  </dd>
                </div>

                <div className="relative">
                  <dt>
                    <svg className="absolute h-6 w-6 text-green" x-description="Heroicon name: outline/check"
                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                         stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"></path>
                    </svg>
                    <p className="ml-9 text-lg leading-6 font-medium text-gray-900">SSO and 2FA for all</p>
                  </dt>
                  <dd className="mt-2 ml-9 text-base text-gray-500">
                    You can use your Github or DigitalOcean OAuth2 integration to login to SimpleBackups and we also support 2FA for all users.
                  </dd>
                </div>

                <div className="relative">
                  <dt>
                    <svg className="absolute h-6 w-6 text-green" x-description="Heroicon name: outline/check"
                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                         stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"></path>
                    </svg>
                    <p className="ml-9 text-lg leading-6 font-medium text-gray-900">No third-party data access</p>
                  </dt>
                  <dd className="mt-2 ml-9 text-base text-gray-500">
                    We don't let any third-party access the data stored in SimpleBackups or access any of our servers
                  </dd>
                </div>

                <div className="relative">
                  <dt>
                    <svg className="absolute h-6 w-6 text-green" x-description="Heroicon name: outline/check"
                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                         stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"></path>
                    </svg>
                    <p className="ml-9 text-lg leading-6 font-medium text-gray-900">Automated tests</p>
                  </dt>
                  <dd className="mt-2 ml-9 text-base text-gray-500">
                    All our releases are tested by our team of engineers and we make sure that all our releases are safe and secure. We also have automated tests for all our releases.
                  </dd>
                </div>

                <div className="relative">
                  <dt>
                    <svg className="absolute h-6 w-6 text-green" x-description="Heroicon name: outline/check"
                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                         stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"></path>
                    </svg>
                    <p className="ml-9 text-lg leading-6 font-medium text-gray-900">No access to your data</p>
                  </dt>
                  <dd className="mt-2 ml-9 text-base text-gray-500">
                    No data of your backup is accessible by anyone from the team. We do not store any of these on our servers.
                    We only have access to logs and meta data.
                  </dd>
                </div>

                <div className="relative">
                  <dt>
                    <svg className="absolute h-6 w-6 text-green" x-description="Heroicon name: outline/check"
                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                         stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"></path>
                    </svg>
                    <p className="ml-9 text-lg leading-6 font-medium text-gray-900">Activity Logs</p>
                  </dt>
                  <dd className="mt-2 ml-9 text-base text-gray-500">
                    We log all activity performed by our team and by our systems. We can track what action was taken by who, at any moment. Same applies to the actions by your team members on your SimpleBackups account.
                  </dd>
                </div>


              </dl>
            </div>
          </Ui.Row>
        </Ui.Container>
      </Ui.Section>

      <Ui.Section className="bg-primary">
        <Ui.Container>
          <Ui.Row>
            <Ui.Typography tag="h2" className="text-center text-white">Secure by design</Ui.Typography>
            <div>
              <div className="md:mx-32">
                <div className=" bg-white p-4 rounded-2xl shadow-2xl">
                  <Image filename="simplebackups-flow-1.png" alt="simple backups flowchart" />
                </div>

                <div className="flex gap-8 mt-12 justify-center">
                  <div>
                    <svg className="absolute h-6 w-6 text-green" x-description="Heroicon name: outline/check"
                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                         stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"></path>
                    </svg>
                    <p className="ml-9 text-lg leading-6 font-medium text-white">No backup data stored</p>
                  </div>
                  <div>
                    <svg className="absolute h-6 w-6 text-green" x-description="Heroicon name: outline/check"
                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                         stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"></path>
                    </svg>
                    <p className="ml-9 text-lg leading-6 font-medium text-white">Secure SSL connections</p>
                  </div>
                  <div>
                    <svg className="absolute h-6 w-6 text-green" x-description="Heroicon name: outline/check"
                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                         stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"></path>
                    </svg>
                    <p className="ml-9 text-lg leading-6 font-medium text-white">Backup encryption</p>
                  </div>
                </div>
              </div>

            </div>
          </Ui.Row>
        </Ui.Container>
      </Ui.Section>

      <Sections.SectionLearn
        title={<>Additional resources</>}
        text={"Our articles and resources about security and privacy"}
        tags={['security']}
      />

      <Sections.SectionFaq color="alt" faqs={['security_certificate', 'security_data_stored', 'security_simplestorage', 'security_what_if_account_is_compromised','security_if_leave_access_backups']}/>

      <VideoModal ref={videoRef} />
    </Layout.Layout>
  )
}

export default SecurityPage
