import Page from "./system/_securityPage";

export default Page

export const frontmatter = {
  pageMetaData: {
    refKey: "security",
    title: "Security & Privacy - Own your backup data, encryption, and more",
    description: "Discover how we make sure your backups are secured and well protected. ISO 27001 certified and GDPR Compliant.",
  }
}